import type { NextPage } from 'next'
import Layout from '../components/Layout';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import Link from 'next/link';

const Home: NextPage<any> = () => {
  return (
    <Layout>
        <div className="flex flex-col md:flex-row space-x-4 px-4">
            <div className="w-full lg:w-3/12 flex flex-col mb-2">
                <Card className="p-4 flex flex-col items-center rounded shadow-lg">
                    <Avatar 
                    alt="Trevor Erickson" 
                    src="/img/te1.png" 
                    sx={{ width: 250, height: 250, border: '2px solid #346f98' }}
                    variant="circular"/>
                    
                    <div className="w-full text-center text-white py-2 mt-4 rounded" style={{backgroundColor: '#346f98'}}>
                        <span className="block uppercase text-2xl font-semibold">Trevor Erickson</span>
                    </div>

                    <ul className="list-disc list-inside mt-4 text-blueGray-700 leading-6">
                      I&apos;m a technology engineer with a passion for cloud computing, AI, web development, and so on... 
                      I&apos;m currently working on a few projects and will be updating this site with more information as things progress. 
                      When I&apos;m not experimenting with technology, I enjoy spending time with my family, and being outdoors!!
                    </ul>
                </Card>
            </div>
            <div className="flex-grow flex flex-col mb-2">
                <Card className="p-4 flex flex-col items-start rounded shadow-lg">
                    <div className="bg-gray-200 rounded">
                      <h2 className="text-2xl font-semibold m-1/2 ml-1 mr-1">What&apos;s New</h2>
                    </div>
                    <ul className="list-disc list-inside text-blueGray-700 leading-6">
                      <p className="mb-4">
                      <br/>                        
                        Welcome! I&apos;ve created this site to give me a place to continuously experiment with tech.
                        You will find this site goes through periods of rapid innovation, followed by periods of inactivity.
                        This is the cadence of life... <br/><br/>

                        Explore the <b>Playground</b>, featuring an interactive sandbox, innovative projects, and practical examples designed to ignite your creativity. 
                        Dive into the <b>Info</b> section for insightful blog posts, comprehensive guides linked to YouTube tutorials, and a curated list of valuable resources. 
                        Learn more about me and my professional journey in the <b>About</b> section, where you can also find my resume.<br/><br/>

                        On the technical side, the backend runs on Docker, and the CI/CD release process is implemented via GitHub Actions. 
                        The entire release workflow is automated, mirroring production environment practices. 
                        Some content is exclusive and requires a login to access. 
                        Join the community and unlock a world of tech knowledge and inspiration!<br/>
                      </p>
                    </ul>
                    <div className="bg-gray-200 rounded mt-8">
                      <h2 className="text-2xl font-semibold m-1/2 ml-1 mr-1">Upcoming</h2>
                    </div>
                    <ul className="list-disc list-inside text-blueGray-700 leading-6">
                      <p className="mb-4">
                        <br/>
                        This is a list of items I&apos;m working on or plan to start soon. 
                      </p>
                      <li><Link href="/info/blog">Add a few blogs</Link></li>
                      <li><Link href="/info/guides">Add some guides related to the development of this site and lessons learned!</Link></li>
                      <li><Link href="/playground/projects">Add an AI chatbot to the Projects section</Link></li>
                    </ul>
                </Card>
            </div>
        </div>
    </Layout>
  );
};

export default Home